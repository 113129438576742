import React, { useRef, useState, useEffect, forwardRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import useHlsPlayer from '../hooks/useHlsPlayer'
import useEventListener from '../hooks/useEventListener'
import find from 'lodash/find'
import useWindowResize from '../hooks/useWindowResize'
import useDebouncedCallback from '../hooks/useDebouncedCallback'
import sortBy from 'lodash/sortBy'
import cn from 'classnames'
import last from 'lodash/last'
import get from 'lodash/get'
import filter from 'lodash/filter'
import gsap from 'gsap'
import composeRefs from '../helpers/composeRefs'
import ResponsiveImage from './ResponsiveImage'
import theme from '../style/theme'
import { isCurrentBreakpointSmall } from '../state/layout'
import { useRecoilValue } from 'recoil'
import inDom from 'dom-helpers/canUseDOM'

const togglePlayer = (player, playing, playingPromiseRef) => {
  if (player) {
    if (playing) {
      playingPromiseRef.current = player.play()
    } else {
      if (playingPromiseRef.current) {
        playingPromiseRef.current.then(() => { player.pause() })
      }
    }
  }
}

export default forwardRef(({ className, vimeoVideo, mobileVimeoVideo, onLoaded, onEnded, show, playing = true, showPosterImage, loop = true, animationDuration = 1 }, ref) => {
  const classes = useStyles()
  const playerRef = useRef()
  const hlsVideoSource = get(find(get(vimeoVideo, ['sizes'], []), source => source.quality === 'hls'), ['url'])
  const mobileHlsVideoSource = get(find(get(mobileVimeoVideo, ['sizes'], []), source => source.quality === 'hls'), ['url'])
  const [url, setUrl] = useState(hlsVideoSource)
  const image = get(vimeoVideo, ['image'])
  const [loaded, setLoaded] = useState(false)
  const playingPromiseRef = useRef()

  const handleLoaded = useCallback((video, hls) => {
    if (onLoaded) onLoaded(video, hls)
    togglePlayer(playerRef.current, playing, playingPromiseRef)
    setLoaded(true)
  }, [playing, onLoaded])

  useRecoilValue(isCurrentBreakpointSmall)
  const source = inDom && (window.innerWidth < theme.breakpoints.values.sm) ? mobileHlsVideoSource || hlsVideoSource : hlsVideoSource

  useHlsPlayer(playerRef, source, source)

  useEventListener(playerRef, 'canplay', handleLoaded)
  useEventListener(playerRef, 'ended', onEnded)

  useWindowResize(useDebouncedCallback((size) => {
    if (!hlsVideoSource) {
      const size = find(sortBy(filter(get(vimeoVideo, ['sizes'], []), source => source.quality !== 'hls'), x => x.width), x => x.width >= window.innerWidth) || last(get(vimeoVideo, ['sizes'], []))
      setUrl(get(size, ['url']))
    }
  }, 200, [vimeoVideo, hlsVideoSource]), true)

  useEffect(() => {
    gsap.to(playerRef.current, { opacity: show ? 1 : 0, duration: animationDuration, ease: 'sine.inOut' })
  }, [show, animationDuration])

  useEffect(() => {
    togglePlayer(playerRef.current, playing, playingPromiseRef)
  }, [playing])

  return (
    <>
      {showPosterImage && <ResponsiveImage {...image} className={classes.posterImage} disableLazy />}
      <video
        ref={composeRefs(ref, playerRef)}
        src={url}
        className={cn(classes.video, className, { show: show && loaded })}
        controls={false}
        autoPlay={playing}
        muted
        loop={loop}
        playsInline
      />
    </>
  )
})

const useStyles = createUseStyles({
  video: {
    position: 'absolute',
    outline: 'none',
    display: 'block',
    opacity: 0,
    transform: 'translateX(-50%) translateY(-50%)',
    top: '50%',
    left: '50%',
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto',
    height: 'auto'
  },
  posterImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  }
})
