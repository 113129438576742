import { useCallback } from 'react'
import { movingDialogOpenState, movingDialogProjectState, movingProjectsForDialog } from '../../MovingDialog/movingDialogState'
import { stillDialogOpenState, stillDialogProjectState } from '../../StillDialog/stillDialogState'
import { useRecoilState } from 'recoil'
import { navigateToProject } from '../../../helpers/navigation'
import { getMovingProjects } from '../../../state/content'

export default (project, projects) => {
  const { projectType } = project
  const [, setStillOpen] = useRecoilState(stillDialogOpenState)
  const [, setStillProject] = useRecoilState(stillDialogProjectState)
  const [, setMovingOpen] = useRecoilState(movingDialogOpenState)
  const [, setMovingProject] = useRecoilState(movingDialogProjectState)
  const [, setMovingProjectsForDialog] = useRecoilState(movingProjectsForDialog)
  const [allProjects] = useRecoilState(getMovingProjects)

  const onClick = useCallback(() => {
    navigateToProject(project)
    if (projectType === 'moving') {
      setMovingOpen(true)
      setMovingProject(project)
      setMovingProjectsForDialog(projects || allProjects)
    } else {
      setStillOpen(true)
      setStillProject(project)
    }
  }, [projects, allProjects, project, setMovingProjectsForDialog, projectType, setStillOpen, setStillProject, setMovingOpen, setMovingProject])

  return onClick
}
