import React, { useMemo } from 'react'
import ErrorBoundary from './ErrorBoundary'
import RichTextSlice from './Slices/RichTextSlice'
import FeaturedProjects from './Slices/FeaturedProjects'
import HomeHero from './Slices/HomeHero'
import ProjectListing from './Slices/ProjectsListing'

const sliceComponentSelector = {
  homeHero: HomeHero,
  featuredProjects: FeaturedProjects,
  rich_text: RichTextSlice,
  projectListing: ProjectListing
}

const Slices = ({ className, slices, page }) => {
  const sliceComponents = useMemo(() => {
    if (!slices) return null
    return slices.map((slice, index) => {
      const Component = sliceComponentSelector[slice.type]
      if (!Component) return null
      return (
        <ErrorBoundary key={`slice-${index}`}>
          <Component slice={slice} page={page} />
        </ErrorBoundary>
      )
    })
  }, [slices, page])

  return sliceComponents
}

export default Slices
