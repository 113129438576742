import React from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../style/theme'
import RichText from '../RichText'

export default ({ slice }) => {
  const classes = useStyles()
  const { anchorId, text, title } = slice
  return (
    <section className={classes.section} id={anchorId}>
      <div className={classes.container}>
        <h3 className={classes.title}>{title}</h3>
        <RichText content={text} className={classes.richText} />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: 18,
    overflowX: 'hidden',
    zIndex: theme.zIndex.section,
    [theme.breakpoints.up('xs')]: {
      fontSize: 22
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: theme.vw(22, 'xl')
    }
  },
  container: {
    margin: [theme.spacing(4), theme.spacing(1)],
    [theme.breakpoints.up('xs')]: {
      margin: [theme.spacing(4), theme.spacing(5)]
    },
    maxWidth: 1080,
    [theme.breakpoints.up('xl')]: {
      maxWidth: theme.vw(1080, 'xl')
    }
  },
  richText: {
  },
  title: {
    marginBottom: '2em'
  }
})
