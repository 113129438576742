import { useEffect } from 'react'

export default (elementRef, event, callback) => {
  useEffect(() => {
    const element = elementRef.current
    if (element && callback) {
      element.addEventListener(event, callback)
      return () => {
        element.removeEventListener(event, callback)
      }
    }
  }, [callback, event, elementRef])
}
