import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import { primaryInput } from 'detect-it'
import theme from '../../../style/theme'

const Item = ({ classNames = {}, project, onMouseEnter, onClick, selected, showSubtitle }) => {
  const handleMouseEnter = useCallback(() => { onMouseEnter(project) }, [onMouseEnter, project])
  const handleClick = useCallback(() => { onClick(project) }, [onClick, project])
  const classes = useStyles()
  return (
    <li>
      <button className={cn(classes.button, classNames.button, { selected })} onMouseEnter={primaryInput !== 'touch' ? handleMouseEnter : null} onClick={handleClick}>
        {project.title}
        {showSubtitle && project.subtitle && (
          <span className={cn(classes.subtitle, { selected })}>
            {project.subtitle}
          </span>
        )}
      </button>
    </li>
  )
}

export default ({
  projects,
  selectedProject,
  setSelectedProject,
  onProjectClick,
  showSubtitle,
  classNames = {},
  ...rest
}) => {
  const classes = useStyles()

  const onMouseEnter = useCallback((project) => {
    if (primaryInput !== 'touch') {
      setSelectedProject(project)
    }
  }, [setSelectedProject])

  return (
    <ul className={cn(classes.list, classNames.list, { loaded: !!selectedProject })} {...rest}>
      {projects.map(project => (
        <Item
          classNames={classNames}
          key={project.title}
          project={project}
          selected={project === selectedProject}
          onMouseEnter={onMouseEnter}
          onClick={onProjectClick}
          showSubtitle={showSubtitle}
        />
      ))}
    </ul>
  )
}

const useStyles = createUseStyles({
  list: {
    position: 'relative',
    padding: 0,
    listStyle: 'none',
    color: theme.colors.black,
    textStroke: `1px ${theme.colors.black}`,
    margin: [theme.spacing(4), theme.spacing(2), theme.spacing(4), theme.spacing(2)],
    [theme.breakpoints.up('sm')]: {
      margin: [theme.spacing(4), theme.spacing(2), theme.spacing(4), theme.spacing(10)]
    },
    '&.loaded': {
      color: 'transparent',
      textStroke: `1px ${theme.colors.white}`
    }
  },
  button: {
    fontSize: 30,
    cursor: 'pointer',
    color: 'currentColor',
    border: 'none',
    backgroundColor: 'transparent',
    margin: 0,
    outline: 'none',
    borderRadius: 0,
    transition: 'color 0.15s ease-in-out',
    display: 'block',
    padding: ['0.5em', 0],
    textAlign: 'left',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      padding: ['0.3em', 0],
      whiteSpace: 'nowrap',
      fontSize: 60
    },
    [theme.breakpoints.up('xxl')]: {
      fontSize: theme.vw(60, 'xxl')
    },
    '&.selected': {
      color: theme.colors.white
    }
  },
  subtitle: {
    fontSize: '0.5em',
    opacity: 0,
    display: 'none',
    marginLeft: '1em',
    transition: 'opacity 0.15s ease-in-out',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-block'
    },
    '&.selected': {
      opacity: 1
    }
  }
})
