import React, { useCallback, useEffect, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import BackgroundVideo from '../BackgroundVideo'
import theme from '../../style/theme'
import { useRecoilState, useRecoilValue } from 'recoil'
import { isSiteLoaded, siteLoadedState } from '../../state/layout'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import Logo from './Logo'
import { getY, scrollToElement } from '../useSmoothScrollbar'
import ResponsiveImage from '../ResponsiveImage'

gsap.registerPlugin(ScrollTrigger)

export default ({ slice: { title, image, vimeoVideo, mobileVimeoVideo } }) => {
  const classes = useStyles()
  const [loadedState, setSiteLoaded] = useRecoilState(siteLoadedState)
  const videoRef = useRef()
  const logoRef = useRef()
  const logoContainerRef = useRef()
  const ready = useRecoilValue(isSiteLoaded)
  const scrolledRef = useRef()

  const onVideoLoaded = useCallback(() => {
    setSiteLoaded({ ...loadedState, videoLoaded: true })
  }, [setSiteLoaded, loadedState])

  useEffect(() => {
    const tl = gsap.timeline()
    const scrollTrigger = ScrollTrigger.create({
      trigger: videoRef.current,
      start: 'top top',
      end: 'bottom top',
      scrub: true,
      animation: tl
    })
    tl.to(videoRef.current, { y: videoRef.current.offsetHeight / 2, ease: 'none' }, 0)
    tl.to(logoContainerRef.current, { y: logoContainerRef.current.offsetHeight / 1.8, ease: 'none' }, 0)
    return () => {
      tl.kill()
      scrollTrigger.kill()
    }
  }, [])

  const onVideoEnded = useCallback(() => {
    if (!scrolledRef.current && getY() === 0 && document.getElementById('featured')) {
      scrolledRef.current = true
      scrollToElement(document.getElementById('featured'))
    }
    videoRef.current.play()
  }, [])

  return (
    <section className={classes.section} id='hero'>
      <h1 className='srOnly'>{title}</h1>
      <ResponsiveImage disableLazy {...image} className={classes.image} />
      <BackgroundVideo
        ref={videoRef}
        onEnded={onVideoEnded}
        vimeoVideo={vimeoVideo}
        mobileVimeoVideo={mobileVimeoVideo}
        show={ready}
        onLoaded={onVideoLoaded}
        loop={false}
        playing
      />
      <div className={classes.logoContainer} ref={logoContainerRef}>
        <Logo className={classes.logo} ref={logoRef} />
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    zIndex: theme.zIndex.section,
    height: '100vh',
    width: '100%',
    overflow: 'hidden',
    backgroundColor: theme.colors.black,
    position: 'relative'
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0
  },
  logoContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    mixBlendMode: 'difference'
  },
  logo: {
    height: 'auto',
    color: theme.colors.white,
    width: '72%',
    [theme.breakpoints.up('sm')]: {
      width: '62%'
    }
  }
})
