import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../style/theme'

export default forwardRef(({ className }, ref) => {
  const classes = useStyles()
  return (
    <div className={className} ref={ref}>
      <svg className={cn(classes.logo, 'desktop')} viewBox='0 0 893 109' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M0 107.469V0H36.664C68.7559 0 90.898 18.422 90.898 53.7344C90.898 89.0467 69.518 107.469 37.4478 107.469H0ZM20.6398 89.0247H35.14C55.7798 89.0247 70.28 80.6015 70.28 53.7344C70.28 26.8672 55.7798 18.422 35.14 18.422H20.6398V89.0247Z' fill='white' />
        <path d='M102.829 86.4865C102.829 69.9024 115.653 62.4199 130.784 61.1509L150.031 59.4663V57.6285C150.102 55.9976 149.834 54.3698 149.246 52.8483C148.657 51.3269 147.76 49.9451 146.611 48.7908C145.463 47.6365 144.087 46.7349 142.573 46.1433C141.059 45.5517 139.44 45.2831 137.817 45.3545H136.576C129.108 45.3545 124.666 50.1021 124.209 54.5654H104.353C105.877 40.1254 118.091 29.2299 137.207 29.2299C156.323 29.2299 169.277 38.9003 169.277 56.8627V90.6436C169.168 96.2843 169.422 101.926 170.039 107.534H151.402C150.887 104.437 150.581 101.308 150.488 98.1699C147.882 101.699 144.462 104.539 140.521 106.445C136.58 108.351 132.237 109.265 127.867 109.109C114.129 109 102.829 100.248 102.829 86.4865ZM133.07 92.9408C135.283 92.9815 137.482 92.5787 139.538 91.7558C141.594 90.9329 143.466 89.7064 145.045 88.1482C146.624 86.5899 147.878 84.7311 148.734 82.6802C149.59 80.6293 150.031 78.4275 150.031 76.2035V74.0156L133.528 75.7003C128.498 76.1598 122.38 78.7633 122.38 85.0644C122.38 89.8339 126.495 92.897 131.851 92.897L133.07 92.9408Z' fill='white' />
        <path d='M238.534 30.6957H259.022L232.59 107.468H203.699L177.29 30.6957H198.365L218.526 92.7219L238.534 30.6957Z' fill='white' />
        <path d='M270.931 20.7193V0H292.311V20.7193H270.931ZM271.693 107.469V30.696H291.549V107.469H271.693Z' fill='white' />
        <path d='M306.985 68.9838C306.985 48.2646 318.742 29.077 340.906 29.077C351.596 29.077 359.847 35.2249 362.895 40.5852V0H382.752V107.469H362.895V97.4262C359.847 102.808 351.596 108.956 340.906 108.956C318.742 109 306.985 89.8124 306.985 68.9838ZM345.478 92.7879C354.644 92.7879 362.895 83.5769 362.895 68.9838C362.895 54.3907 354.644 45.2016 345.478 45.2016H344.259C334.331 45.2016 326.841 54.2594 326.841 68.8306C326.841 83.4019 334.331 92.7879 344.259 92.7879H345.478Z' fill='white' />
        <path d='M551.724 0H573.104L546.673 107.469H519.937L503.586 29.1644L487.105 107.469H460.369L434.09 0H455.317L474.411 85.1959L491.241 0H516.453L533.261 85.1959L551.724 0Z' fill='white' />
        <path d='M575.086 86.4865C575.086 69.9024 587.931 62.4199 603.062 61.1509L622.309 59.4663V57.6284C622.38 55.9957 622.112 54.3659 621.522 52.8429C620.932 51.3199 620.033 49.937 618.882 48.7824C617.731 47.6278 616.353 46.7267 614.837 46.1365C613.32 45.5463 611.698 45.28 610.073 45.3545H608.81C601.321 45.3545 596.901 50.1021 596.444 54.5654H576.588C578.112 40.1254 590.326 29.2299 609.42 29.2299C628.514 29.2299 641.468 38.8346 641.468 56.797V90.5779C641.359 96.2186 641.614 101.861 642.23 107.468H623.593C623.077 104.364 622.771 101.228 622.679 98.0823C620.075 101.613 616.654 104.454 612.713 106.36C608.772 108.266 604.429 109.179 600.058 109.022C586.407 109 575.086 100.248 575.086 86.4865ZM605.349 92.9408C607.561 92.9815 609.76 92.5787 611.816 91.7558C613.872 90.9329 615.744 89.7064 617.323 88.1481C618.902 86.5899 620.157 84.7311 621.013 82.6802C621.869 80.6293 622.309 78.4275 622.309 76.2035V74.0156L605.806 75.7003C600.755 76.1598 594.658 78.7633 594.658 85.0644C594.658 89.8339 598.773 92.897 604.129 92.897L605.349 92.9408Z' fill='white' />
        <path d='M661.411 107.469V0H681.267V107.469H661.411Z' fill='white' />
        <path d='M701.733 107.469V0H721.611V107.469H701.733Z' fill='white' />
        <path d='M741.315 20.7193V0H762.695V20.7193H741.315ZM742.077 107.469V30.696H761.933V107.469H742.077Z' fill='white' />
        <path d='M782.398 107.469V30.6962H802.254V41.8981C804.679 37.9536 808.079 34.7074 812.124 32.4759C816.168 30.2444 820.719 29.1036 825.332 29.1646C843.664 29.1646 851.916 41.4386 851.916 58.329V107.469H832.06V60.6263C832.06 50.6496 827.705 45.3112 819.976 45.3112H818.757C809.591 45.3112 802.254 54.0627 802.254 64.8052V107.491L782.398 107.469Z' fill='white' />
        <path d='M870.858 107.469V85.1958H893.022V107.469H870.858Z' fill='white' />
      </svg>
      <svg className={cn(classes.logo, 'mobile')} viewBox='0 0 312 172' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path d='M4.76794 70.2741V0.204102H28.8004C49.836 0.204102 64.3497 12.2154 64.3497 35.2392C64.3497 58.263 50.3355 70.2741 29.3142 70.2741H4.76794ZM18.2969 58.263H27.8015C41.3305 58.263 50.835 52.7567 50.835 35.2392C50.835 17.7217 41.3305 12.2153 27.8015 12.2153H18.2969V58.263Z' fill='white' />
        <path d='M72.1703 56.5654C72.1703 45.7525 80.576 40.8739 90.4944 40.0465L103.11 38.9481V37.7356C103.157 36.6722 102.981 35.6109 102.595 34.6189C102.21 33.6269 101.621 32.7259 100.869 31.9733C100.116 31.2207 99.2143 30.6328 98.2219 30.2471C97.2295 29.8614 96.1677 29.6864 95.1039 29.7329H94.2905C89.3955 29.7329 86.4842 32.8426 86.1845 35.7384H73.1693C74.1682 26.3377 82.1743 19.2195 94.7043 19.2195C107.234 19.2195 115.726 25.5247 115.726 37.2363V59.2615C115.654 62.9393 115.821 66.6179 116.225 70.2741H104.009C103.67 68.2549 103.47 66.2152 103.41 64.1688C101.704 66.4727 99.4626 68.3263 96.8789 69.5692C94.2952 70.812 91.4474 71.4066 88.582 71.3013C79.577 71.2728 72.1703 65.5666 72.1703 56.5654ZM91.9928 60.7594C93.4426 60.7878 94.8834 60.5269 96.231 59.992C97.5787 59.457 98.806 58.6588 99.8412 57.644C100.876 56.6291 101.699 55.4179 102.26 54.0815C102.821 52.7451 103.11 51.3103 103.11 49.8609V48.4344L92.2925 49.5328C88.9959 49.8466 84.9857 51.544 84.9857 55.6381C84.9857 58.7479 87.6829 60.7451 91.1936 60.7451L91.9928 60.7594Z' fill='white' />
        <path d='M161.122 20.2323H174.551L157.226 70.2741H138.288L120.977 20.2323H134.792L148.007 60.6594L161.122 20.2323Z' fill='white' />
        <path d='M182.357 13.7132V0.204102H196.372V13.7132H182.357ZM182.857 70.274V20.2323H195.872V70.274H182.857Z' fill='white' />
        <path d='M205.99 45.2533C205.99 31.73 213.697 19.2195 228.225 19.2195C235.232 19.2195 240.641 23.2279 242.639 26.7371V0.204102H255.654V70.2741H242.639V63.7693C240.641 67.2642 235.232 71.2727 228.225 71.2727C213.697 71.2727 205.99 58.7623 205.99 45.2533ZM231.222 60.7594C237.23 60.7594 242.639 54.7538 242.639 45.2533C242.639 35.7527 237.23 29.7329 231.222 29.7329H230.422C223.915 29.7329 219.006 35.6385 219.006 45.1533C219.006 54.6681 223.915 60.7594 230.422 60.7594H231.222Z' fill='white' />
        <path d='M81.8746 92.7987H95.9031L78.578 162.869H61.0531L50.3355 111.814L39.518 162.869H21.9931L4.76794 92.7987H18.6965L31.2123 148.347L42.2295 92.7987H58.7412L69.7585 148.347L81.8746 92.7987Z' fill='white' />
        <path d='M97.2018 149.074C97.2018 138.276 105.607 133.383 115.526 132.57L128.142 131.457V130.259C128.19 129.195 128.017 128.132 127.631 127.139C127.246 126.146 126.658 125.245 125.905 124.492C125.152 123.738 124.249 123.151 123.256 122.766C122.263 122.381 121.2 122.207 120.135 122.256H119.336C114.427 122.256 111.516 125.351 111.216 128.262H98.2008C99.1997 118.847 107.22 111.743 119.736 111.743C132.252 111.743 140.757 118.048 140.757 129.759V151.785C140.686 155.462 140.852 159.141 141.257 162.797H129.041C128.703 160.778 128.502 158.738 128.441 156.692C126.736 158.993 124.497 160.845 121.916 162.087C119.335 163.33 116.491 163.926 113.628 163.824C104.608 163.796 97.2018 158.09 97.2018 149.074ZM117.024 153.354C118.475 153.38 119.916 153.118 121.264 152.581C122.611 152.045 123.839 151.245 124.874 150.229C125.909 149.213 126.731 148.001 127.292 146.664C127.853 145.327 128.142 143.891 128.142 142.441V141.015L117.324 142.113C114.027 142.412 110.017 144.124 110.017 148.218C110.017 151.328 112.729 153.325 116.225 153.325L117.024 153.354Z' fill='white' />
        <path d='M153.787 162.797V92.7987H166.802V162.869L153.787 162.797Z' fill='white' />
        <path d='M180.217 162.797V92.7987H193.232V162.869L180.217 162.797Z' fill='white' />
        <path d='M206.147 106.236V92.7987H220.176V106.308L206.147 106.236ZM206.647 162.797V112.77H219.733V162.826L206.647 162.797Z' fill='white' />
        <path d='M233.091 162.797V112.77H246.106V120.088C247.69 117.509 249.917 115.387 252.569 113.929C255.221 112.471 258.207 111.727 261.234 111.771C273.25 111.771 278.644 119.788 278.644 130.786V162.826H265.629V132.298C265.629 125.779 262.775 122.313 257.723 122.313H256.924C250.916 122.313 246.106 128.019 246.106 135.023V162.854L233.091 162.797Z' fill='white' />
        <path d='M293.486 162.797V148.276H308V162.797H293.486Z' fill='white' />
      </svg>
    </div>
  )
})

const useStyles = createUseStyles({
  logo: {
    '&.desktop': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'block'
      }
    },
    '&.mobile': {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    }
  }
})
