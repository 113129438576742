import React, { useCallback, useRef, useEffect, useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import theme from '../../../style/theme'
import FeaturedMoving from './FeaturedMoving'
import FeaturedStills from './FeaturedStills'
import find from 'lodash/find'
import { useRecoilState } from 'recoil'
import { selectedTagState } from './featuredProjectsState'
import Tabs from './Tabs'
import Noise from '../../Noise'
import cn from 'classnames'

export default ({ slice, page }) => {
  const classes = useStyles()
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTagState)
  const stillPanelRef = useRef()
  const movingPanelRef = useRef()
  const timelineRef = useRef()
  const localsRef = useRef({ animating: false })
  const { anchorId } = slice

  const movingProject = useMemo(() => {
    return slice.moving.map(p => find(page.projects, project => project.id === p.id))
  }, [slice, page])

  const stillProjects = useMemo(() => {
    return slice.still.map(p => find(page.projects, project => project.id === p.id))
  }, [slice, page])

  const onTabClicked = useCallback((tabId) => {
    setSelectedTab(tabId)
  }, [setSelectedTab])

  useEffect(() => {
    localsRef.current.animating = true
    if (timelineRef.current) timelineRef.current.kill()
    const selectedPanel = selectedTab === 'moving' ? movingPanelRef.current : stillPanelRef.current
    const unselectedPanel = selectedTab === 'moving' ? stillPanelRef.current : movingPanelRef.current
    timelineRef.current = gsap.timeline()
    timelineRef.current.to(selectedPanel, { opacity: 1, ease: 'power2.inOut', duration: 0.5, zIndex: 1 })
    timelineRef.current.to(unselectedPanel, { opacity: 0, ease: 'power2.inOut', duration: 0.5, zIndex: 2, onComplete: () => { localsRef.current.animating = false } }, 0)
  }, [selectedTab])

  return (
    <section className={classes.section} id={anchorId}>
      <Noise fixed={false} />
      <Tabs title='Featured Projects' onTabClicked={onTabClicked} selectedTab={selectedTab} />
      <div className={cn(classes.movingPanel, { selected: selectedTab === 'moving' })} ref={movingPanelRef}>
        <div className={classes.inner}>
          <FeaturedMoving show={selectedTab === 'moving'} projects={movingProject} />
        </div>
      </div>
      <div className={cn(classes.stillPanel, { selected: selectedTab === 'still' })} ref={stillPanelRef}>
        <div className={classes.inner}>
          <FeaturedStills show={selectedTab === 'still'} projects={stillProjects} />
        </div>
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    backgroundColor: theme.colors.white,
    minHeight: `calc(100vh - ${theme.spacing(10)})`,
    position: 'relative',
    overflow: 'hidden',
    zIndex: theme.zIndex.section,
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up('sm')]: {
      minHeight: `calc(100vh - ${theme.spacing(16)})`,
      paddingTop: theme.spacing(16)
    }
  },
  movingPanel: {
    pointerEvents: 'none',
    '&.selected': {
      pointerEvents: 'all'
    }
  },
  stillPanel: {
    overflow: 'hidden',
    opacity: 0,
    position: 'absolute',
    backgroundColor: theme.colors.black,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    color: theme.colors.white,
    pointerEvents: 'none',
    '&.selected': {
      pointerEvents: 'all'
    }
  },
  inner: {
    width: '100%'
  }
})
