import React from 'react'
import { Helmet } from 'react-helmet'
import { createUseStyles } from 'react-jss'
import Slices from '../components/Slices'
import theme from '../style/theme'
import Noise from '../components/Noise'
import useSmoothScrollbar from '../components/useSmoothScrollbar'
import { primaryInput } from 'detect-it'

const Main = ({ page }) => {
  const classes = useStyles()
  const { ref: scrollRef } = useSmoothScrollbar()
  const { slices } = page

  // DO NOT REMOVE THE USELESS DIV, it is there so that smooth-scrollbar does not crash when it unmounts.
  // See https://github.com/idiotWu/react-smooth-scrollbar/issues/9
  return (
    <main className={classes.main} ref={scrollRef}>
      <div>
        <Noise />
        <Slices slices={slices} page={page} />
      </div>
    </main>
  )
}

export default ({ pageContext }) => {
  const { page } = pageContext
  const { title, seo: meta } = page
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name='title' content={meta.metaTitle} />
        {meta.metaDescription && <meta name='description' content={meta.metaDescription} />}
        {meta.metaKeywords && <meta name='keywords' content={meta.metaKeywords} />}
        {meta.ogUrl && <meta property='og:url' content={meta.ogUrl} />}
        <meta property='og:site_name' content={meta.siteName} />
        <meta property='og:title' content={meta.metaTitle} />
        <meta property='og:type' content={meta.metaType} />
        {meta.metaDescription && <meta property='og:description' content={meta.metaDescription} />}
        {meta.metaImage && [
          <meta property='og:image' content={meta.metaImage.url} key={0} />,
          <meta property='og:image:width' content={meta.metaImage.width} key={1} />,
          <meta property='og:image:height' content={meta.metaImage.height} key={2} />
        ]}
        {meta.metaImage && <meta name='twitter:card' content={meta.twitterCardType} />}
        {meta.siteName && <meta name='twitter:site' content={meta.siteName} />}
        <meta name='twitter:title' content={meta.metaTitle} />
        {meta.metaDescription && <meta name='twitter:description' content={meta.metaDescription} />}
        {meta.metaImage && <meta name='twitter:image' content={meta.metaImage.url} />}
        <link rel='canonical' href={meta.canonicalUrl} />
      </Helmet>
      <Main page={page} />
    </>
  )
}

const useStyles = createUseStyles({
  main: {
    backgroundColor: theme.colors.background,
    ...(primaryInput === 'touch'
      ? {}
      : {
          overflowY: 'auto',
          overflowX: 'hidden',
          height: '100vh',
          width: '100%'
        })
  }
})
