import React, { useMemo } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../../../style/theme'
import ProjectTile from './ProjectTile'
import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'

export default ({ slice: { title, anchorId, projects }, page: { projects: allProjects } }) => {
  const classes = useStyles()

  const listing = useMemo(() => {
    if (isEmpty(projects)) return allProjects
    return projects.map(p => find(allProjects, project => project.id === p.id))
  }, [allProjects, projects])

  return (
    <section className={classes.section} id={anchorId}>
      <h3 className={classes.title}>{title}</h3>
      <div className={classes.grid}>
        {listing.map((project, i) => (
          <ProjectTile
            key={project.id}
            project={project}
            projects={listing}
          />
        ))}
      </div>
    </section>
  )
}

const useStyles = createUseStyles({
  section: {
    zIndex: theme.zIndex.section,
    minHeight: '100vh',
    padding: [theme.spacing(4), theme.spacing(1)],
    [theme.breakpoints.up('xs')]: {
      padding: [theme.spacing(8), theme.spacing(5)]
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  grid: {
    display: 'grid',
    gridGap: 6,
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr'
    }
  }
})
