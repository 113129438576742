import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import { selectedStillState } from './featuredProjectsState'
import { useRecoilState } from 'recoil'
import ProjectListing from './ProjectListing'
import theme from '../../../style/theme'
import ResponsiveImage from '../../ResponsiveImage'
import get from 'lodash/get'
import cn from 'classnames'
import { primaryInput } from 'detect-it'
import { stillDialogOpenState, stillDialogProjectState } from '../../StillDialog/stillDialogState'
import { navigateToProject } from '../../../helpers/navigation'

const StillImage = ({ project, selected }) => {
  const image = get(project, ['still', 'images', 0])
  const classes = useImageStyles({ aspect: get(image, ['aspect']) })
  if (!image) return null
  return (
    <div key={project.title} className={cn(classes.image, { show: selected })}>
      <ResponsiveImage {...image} />
    </div>
  )
}

const useImageStyles = createUseStyles({
  image: {
    position: 'absolute',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    width: '100%',
    opacity: 0,
    transition: 'opacity 0.25s ease-in-out',
    maxWidth: ({ aspect }) => aspect > 1 ? '100%' : '25vw',
    '&.show': {
      opacity: 1
    }
  }
})

export default ({ projects }) => {
  const classes = useStyles()
  const [selectedProject, setSelectedProject] = useRecoilState(selectedStillState)
  const [, setOpen] = useRecoilState(stillDialogOpenState)
  const [, setProject] = useRecoilState(stillDialogProjectState)

  const onProjectClick = useCallback((project) => {
    navigateToProject(project)
    setOpen(true)
    setProject(project)
  }, [setOpen, setProject])

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        {projects.map(project => <StillImage key={project.title} project={project} selected={selectedProject === project} />)}
      </div>
      <ProjectListing
        projects={projects}
        selectedProject={selectedProject}
        setSelectedProject={setSelectedProject}
        onProjectClick={onProjectClick}
        showSubtitle={false}
        classNames={{
          list: classes.list,
          button: classes.button
        }}
      />
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    minHeight: `calc(100vh - ${theme.spacing(10)})`,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      minHeight: `calc(100vh - ${theme.spacing(16)})`,
      paddingTop: theme.spacing(16)
    }
  },
  imageContainer: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '50%',
    display: 'none',
    flexShrink: 1,
    flexGrow: 1,
    alignSelf: 'flex-start',
    margin: [theme.spacing(8), theme.spacing(6), theme.spacing(8), theme.spacing(5)],
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      height: 'calc(100% - 14em)'
    }
  },
  list: {
    color: theme.colors.white,
    margin: [theme.spacing(4), theme.spacing(2), theme.spacing(4), theme.spacing(2)],
    [theme.breakpoints.up('sm')]: {
      margin: [theme.spacing(4), theme.spacing(10), theme.spacing(4), theme.spacing(2)]
    },
    [theme.breakpoints.up('lg')]: {
      width: '50%'
    },
    '&.loaded': {
      color: theme.colors.white,
      textStroke: 0
    }
  },
  button: {
    textAlign: 'right',
    marginLeft: 'auto',
    color: theme.colors,
    ...(primaryInput === 'touch'
      ? {}
      : {
          opacity: 0.2,
          '&.selected': {
            opacity: 1
          }
        })
  }
})
