import React, { useCallback, useRef } from 'react'
import { createUseStyles } from 'react-jss'
import noise from './noise.png'
import { useScrollListener } from '../useSmoothScrollbar'
import { primaryInput } from 'detect-it'
import theme from '../../style/theme'

function Noise ({ fixed = true }) {
  const classes = useStyles({ fixed })
  const ref = useRef()

  const onScroll = useCallback(({ y }) => {
    if (fixed && ref.current && primaryInput !== 'touch') {
      ref.current.style.transform = `translate3d(0,${y}px,0)`
    }
  }, [fixed])

  useScrollListener(onScroll)

  return (
    <div className={classes.noise} ref={ref} />
  )
}

const useStyles = createUseStyles({
  noise: {
    width: '100%',
    minHeight: '100vh',
    height: '100vh',
    position: ({ fixed }) => fixed ? 'fixed' : 'absolute',
    left: 0,
    top: 0,
    pointerEvents: 'none',
    display: 'block',
    zIndex: theme.zIndex.noise,
    overflow: 'hidden',
    '&:after': {
      content: '""',
      width: 'calc(100% + 20rem)',
      height: 'calc(100% + 20rem)',
      backgroundPosition: '50%',
      position: 'absolute',
      left: '-10rem',
      top: '-10rem',
      backgroundImage: `url(${noise})`,
      animation: '$noise 1s steps(2) infinite'
    }
  },
  '@keyframes noise': {
    '0%': {
      transform: 'translate3d(0,9rem,0)'
    },
    '10%': {
      transform: 'translate3d(-1rem,-4rem,0)'
    },
    '20%': {
      transform: 'translate3d(-8rem,2rem,0)'
    },
    '30%': {
      transform: 'translate3d(9rem,-9rem,0)'
    },
    '40%': {
      transform: 'translate3d(-2rem,7rem,0)'
    },
    '50%': {
      transform: 'translate3d(-9rem,-4rem,0)'
    },
    '60%': {
      transform: 'translate3d(2rem,6rem,0)'
    },
    '70%': {
      transform: 'translate3d(7rem,-8rem,0)'
    },
    '80%': {
      transform: 'translate3d(-9rem,1rem,0)'
    },
    '90%': {
      transform: 'translate3d(6rem,-5rem,0)'
    },
    to: {
      transform: 'translate3d(-7rem,0,0)'
    }
  }
}, { name: 'Noise' })

export default Noise
