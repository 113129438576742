import React from 'react'
import { createUseStyles } from 'react-jss'
import MovingProjectTile from '../../MovingProjectTile'
import theme from '../../../style/theme'

export default ({ show, projects }) => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.grid}>
        {projects && projects.map((project, i) => (
          <MovingProjectTile key={i} project={project} projects={projects} />
        ))}
      </div>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'relative',
    margin: [0, theme.spacing(1)],
    minHeight: `calc(100vh - ${theme.spacing(10)})`,
    [theme.breakpoints.up('xs')]: {
      minHeight: `calc(100vh - ${theme.spacing(16)})`,
      margin: [0, theme.spacing(5)]
    }
  },
  title: {
    marginBottom: theme.spacing(2)
  },
  grid: {
    width: '100%',
    display: 'grid',
    gridGap: 6,
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr 1fr'
    }
  }
})
