import React, { useEffect, useRef, useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import ResponsiveImage from '../../ResponsiveImage'
import cn from 'classnames'

const Image = ({ image, index, classes, onImageLoaded }) => {
  const handleImageLoaded = useCallback(() => { onImageLoaded(index) }, [index, onImageLoaded])
  return <ResponsiveImage {...image} className={cn(classes.image, { hide: index !== 0 })} onImageLoaded={handleImageLoaded} />
}

const ImageTicker = ({ className, images = [], animate }) => {
  const classes = useStyles()
  const containerRef = useRef()
  const currentFrameRef = useRef(0)
  const loadedImages = useRef({ 0: true })

  useEffect(() => {
    const bufferNextImage = () => {
      const nextFrame = (currentFrameRef.current + 1) % images.length
      containerRef.current.children[nextFrame].style.display = 'block'
      containerRef.current.children[nextFrame].style.visibility = 'hidden' // This will load the image ready to animate next
    }
    const tick = () => {
      const nextFrame = (currentFrameRef.current + 1) % images.length
      if (loadedImages.current[nextFrame]) {
        containerRef.current.children[currentFrameRef.current].style.visibility = 'hidden'
        currentFrameRef.current = nextFrame
        containerRef.current.children[currentFrameRef.current].style.display = 'block'
        containerRef.current.children[currentFrameRef.current].style.visibility = 'visible'
        bufferNextImage()
      }
    }

    let intervalId
    if (containerRef.current && animate) {
      bufferNextImage()
      tick()
      intervalId = setInterval(tick, 250)
    }
    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [animate, images])

  const imageLoaded = useCallback((index) => {
    loadedImages.current = {
      ...loadedImages.current,
      [index]: true
    }
  }, [loadedImages])

  return (
    <div className={className} ref={containerRef}>
      {images.map((image, i) => <Image key={i} classes={classes} index={i} image={image} onImageLoaded={imageLoaded} />)}
    </div>
  )
}

const ImageTickerWrapper = ({ images, ...rest }) => {
  if (images.length === 0) return null
  if (images.length === 1) {
    return <ResponsiveImage {...images[0]} />
  }
  return <ImageTicker {...rest} images={images} />
}

const useStyles = createUseStyles({
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    visibility: 'hidden',
    '&:first-child': {
      visibility: 'visible'
    }
  }
}, { name: 'ImageTicker' })

export default ImageTickerWrapper
