import React, { useCallback } from 'react'
import { createUseStyles } from 'react-jss'
import cn from 'classnames'
import theme from '../../../style/theme'

export default ({ title, onTabClicked, selectedTab }) => {
  const classes = useStyles()
  const onMovingClick = useCallback(() => { onTabClicked('moving') }, [onTabClicked])
  const onStillClick = useCallback(() => { onTabClicked('still') }, [onTabClicked])
  return (
    <div className={classes.container}>
      <h3>{title}</h3>
      <ul className={classes.filters}>
        <li>
          <button onClick={onMovingClick} className={cn(classes.filterButton, { selected: selectedTab === 'moving' })}>
            Moving
          </button>
          <button onClick={onStillClick} className={cn(classes.filterButton, { selected: selectedTab === 'still' })}>
            Still
          </button>
        </li>
      </ul>
    </div>
  )
}

const useStyles = createUseStyles({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1000,
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: [theme.spacing(2), theme.spacing(1)],
    color: theme.colors.white,
    mixBlendMode: 'difference',
    [theme.breakpoints.up('sm')]: {
      margin: [theme.spacing(8), theme.spacing(5)],
      display: 'flex'
    }
  },
  filters: {
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    flexWrap: 'wrap',
    margin: [0, -8],
    [theme.breakpoints.up('sm')]: {
      margin: [0, -14]
    }
  },
  filterButton: {
    opacity: 0.4,
    margin: [2, 4],
    fontSize: 16,
    border: 'none',
    borderRadius: '0',
    backgroundColor: 'transparent',
    padding: [4, 8],
    fontWeight: 'bold',
    cursor: 'pointer',
    outline: 'none',
    position: 'relative',
    transition: 'opacity 0.15s ease-in-out',
    color: theme.colors.white,
    mixBlendMode: 'difference',
    [theme.breakpoints.up('sm')]: {
      margin: [0, theme.spacing(0.5)],
      fontSize: 22
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 30
    },
    '&.selected': {
      opacity: 1
    }
  }
})
