import React, { useCallback, useMemo, useState } from 'react'
import { createUseStyles } from 'react-jss'
import theme from '../style/theme'
import BackgroundVideo from './BackgroundVideo'
import cn from 'classnames'
import useProjectClick from './Slices/ProjectsListing/useProjectClick'
import get from 'lodash/get'
import { primaryInput } from 'detect-it'
import ResponsiveImage from './ResponsiveImage'

const MovingProjectTile = ({ project, hovering = false }) => {
  const classes = useStyles()
  if (primaryInput === 'touch') {
    const image = get(project, ['moving', 'thumbnailVideo', 'image']) || get(project, ['moving', 'fullVideo', 'image'])
    return <ResponsiveImage {...image} className={classes.image} />
  }
  return (
    <BackgroundVideo
      playing={hovering}
      show={hovering}
      vimeoVideo={project.moving.thumbnailVideo || project.moving.fullVideo}
      showPosterImage
      className={classes.video}
      animationDuration={0.25}
    />
  )
}

export default ({ project, projects, className }) => {
  const classes = useStyles()
  const [hovering, setHovering] = useState()
  const tags = useMemo(() => { return project.tags?.map(x => x.id) }, [project])
  const onMouseEnter = useCallback(() => { setHovering(true) }, [])
  const onMouseLeave = useCallback(() => { setHovering(false) }, [])
  const { title, subtitle, textColor } = project

  const onClick = useProjectClick(project, projects)

  return (
    <article className={cn(classes.article, className, tags)} data-id={project.id}>
      <div className={classes.articleWrapper} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onFocus={null} onBlur={null} role='button' tabIndex={-1} onClick={onClick} onKeyDown={null}>
        <div className={classes.inner}>
          <div className={classes.videoImageContainer}>
            <MovingProjectTile project={project} hovering={hovering} />
          </div>
          <div className={cn(classes.titleContainer, textColor)}>
            <span className={classes.title}>{title}</span>
            <span className={classes.subtitle}>{subtitle}</span>
          </div>
        </div>
      </div>
    </article>
  )
}

const useStyles = createUseStyles({
  article: {
    width: '100%'
  },
  articleWrapper: {
    position: 'relative',
    cursor: 'pointer',
    outline: 'none',
    paddingTop: '56.34%'
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    flexDirection: 'column'
  },
  videoImageContainer: {
    position: 'relative',
    overflow: 'hidden',
    flexGrow: 1,
    flexShrink: 1
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0
  },
  video: {
    height: '100%'
  },
  titleContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.colors.white,
    '&.black': {
      color: theme.colors.black
    }
  },
  title: {
    fontWeight: 'bold',
    display: 'inline-block',
    fontSize: 30,
    lineHeight: 1.2,
    [theme.breakpoints.up('sm')]: {
      fontSize: 36
    }
  },
  subtitle: {
    fontSize: 10,
    [theme.breakpoints.up('sm')]: {
      fontSize: 16
    }
  }
})
